import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import sha256 from 'crypto-js/sha256'
import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'

import gift from '../../assets/wallet-management/gift.webp'
import {
  BannerPopupModal,
  DefaultButton,
  Loading,
  MessageModal,
  Spinner,
  ToastMessage,
} from '../../components/common'
import Icon from '../../components/icon'
import {
  AmountSelector,
  PromotionTermsModal,
  TermsCheckbox,
} from '../../components/payment-form'
import type { Promo } from '../../components/payment-form/promotion-input'
import { LOADING_HTML } from '../../constants/casino'
import useDimensions from '../../hooks/useDimensions'
import type { User } from '../../store/slices/userSlice'
import { useBoundStore } from '../../store/store'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import { getDeviceInfo } from '../../utils/device'
import { numberWithCommas } from '../../utils/number'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins';
  font-size: 16px;
  text-transform: uppercase;
  color: var(--highlight);
  margin-bottom: 16px;
  p {
    display: flex;
    align-items: center;
    img {
      height: 20px;
      margin-right: 16px;
    }
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    p img {
      height: 25px;
    }
  }
`

const AmountInput = styled.div`
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid var(--secondary-txt-20);
  margin-bottom: 16px;
  label {
    font-size: 14px;
    font-family: 'Poppins';
    color: var(--highlight);
    margin-right: 12px;
  }
  input {
    flex: 1;
    text-align: right;
    font-size: 14px;
    font-family: 'Poppins';
    color: var(--txt);
  }
  @media (min-width: 1200px) {
    label,
    input {
      font-size: 16px;
    }
  }
`

const Total = styled.p`
  text-align: right;
  font-size: 14px;
  font-family: 'Poppins';
  color: var(--highlight);
  width: 100%;
`

const ErrorMessage = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 6px;
  color: rgb(255, 64, 88);
`

const PromoList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-top: 16px;
  gap: 12px;
  @media (min-width: 1200px) {
    /* height */
    &::-webkit-scrollbar {
      height: 18px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--tertiary);
    }
  }
`

const PromoItem = styled.button`
  width: 190px;
  min-width: 190px;
  height: 70px;
  padding: 16px 12px;
  border-radius: 6px;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  position: relative;
  .name {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 700;
    color: var(--txt);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
  .icon {
    width: 32px;
    height: 32px;
    margin-left: 12px;
    filter: grayscale(1);
  }
  .text {
    position: absolute;
    bottom: 2px;
    left: 12px;
    width: calc(100% - 24px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Poppins';
    font-size: 8px;
    color: var(--error);
    text-align: left;
    opacity: 0;
  }
  &.active {
    background-color: var(--highlight);
    .name {
      color: #fff;
    }
    .icon {
      filter: grayscale(0);
    }
    .text {
      opacity: 1;
    }
  }
`

const StyledIframe = styled.iframe`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  border: none;
  outline: none;
  position: absolute;
  &.active {
    position: static;
    opacity: 1;
    pointer-events: auto;
    width: 100%;
    height: calc(100vh - 180px);
  }
  @media (min-width: 1200px) {
    &.active {
      height: calc(100vh - 230px);
    }
  }
`

const SubmitContainer = styled.div`
  margin-top: 24px;
`

const PaymentForm = () => {
  const email = useBoundStore(state => state.user.email)
  const phone = useBoundStore(state => state.user.phone)
  const userId = useBoundStore(state => state.user.userID)
  const birthday = useBoundStore(state => state.user.birthday)
  const firstName = useBoundStore(state => state.user.first_name)
  const lastName = useBoundStore(state => state.user.last_name)
  const setUser = useBoundStore(state => state.user.setUser)
  const selectedPaymentChannel = useBoundStore(
    state => state.payment.selectedPaymentChannel,
  )
  const setSelectedPaymentChannel = useBoundStore(
    state => state.payment.setSelectedPaymentChannel,
  )
  const messageModal = useModal(MessageModal)
  const promotionTermsModal = useModal(PromotionTermsModal)
  const bannerPopupModal = useModal(BannerPopupModal)
  const toastMessage = useModal(ToastMessage)
  const {
    t,
    i18n: { language },
  } = useTranslation('walletManagement')
  const { isDesktop } = useDimensions()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [amount, setAmount] = useState<number>(500)
  const [selectedPromo, setSelectedPromo] = useState<Promo | null>(null)
  const [promoList, setPromoList] = useState<Promo[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isReadTerms, setIsReadTerms] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const isCrypto = useMemo(() => {
    return Number(selectedPaymentChannel?.id) === 369
  }, [selectedPaymentChannel])

  useEffect(() => {
    if (isDesktop && hasSubmitted && iframeRef.current) {
      iframeRef.current.classList.remove('active')
      iframeRef.current.srcdoc = ''
      setHasSubmitted(false)
    }
  }, [selectedPaymentChannel])

  useEffect(() => {
    const fetchPromoList = async () => {
      const response = await api.post<
        ApiResponse<Array<Omit<Promo, 'label'> & { title: string }>>
      >('/payment.php', {
        type: 'autopromotion_list',
        amount: amount,
      })
      const { status, info } = response.data
      if (status === 1) {
        const formatInfo = info.map(item => ({
          label: item.title,
          ...item,
        }))
        if (selectedPromo) {
          setSelectedPromo(prev => {
            const samePromo = formatInfo.find(
              promo => (prev as Promo).id === promo.id,
            )
            if (samePromo) return samePromo
            return formatInfo[0]
          })
        } else {
          const firstDepositBonus = formatInfo.find(
            promo => [234, 235].includes(+promo.id) && !promo.disabled,
          )
          if (firstDepositBonus) {
            bannerPopupModal.show({
              imgUrl: `https://www.u2d8899.com/mexicoimages/banner/100_bonus_${language}.webp`,
            })
          }
        }
        setPromoList(formatInfo)
      } else {
        setSelectedPromo(null)
        setPromoList([])
      }
    }
    fetchPromoList()
  }, [amount])

  useEffect(() => {
    const messageHandler = (message: MessageEvent<string>) => {
      try {
        const data = JSON.parse(message.data) as Record<'type', unknown>
        if (data?.type === 'back' && iframeRef.current) {
          iframeRef.current.classList.remove('active')
          iframeRef.current.srcdoc = ''
          setHasSubmitted(false)
        }
      } catch (error) {
        // do nothing
      }
    }

    window.addEventListener('message', messageHandler)
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [])

  useEffect(() => {
    if (isCrypto) onSubmit(null)
  }, [isCrypto])

  const onSubmit = async (tab: Window | null) => {
    if (!selectedPaymentChannel) return
    if (!isReadTerms && selectedPromo) {
      toastMessage.show({
        text: t('accept-terms'),
        isValid: false,
      })
      return
    }

    const reqBody = {
      type: 'submitpay',
      amount: amount,
      pay_type: 1,
      line_type: selectedPaymentChannel.line_type,
      autopromo: selectedPromo?.id ?? 0,
      pay_id: selectedPaymentChannel.id,
      email: email,
      meta: getDeviceInfo(),
    }

    setIsLoading(true)
    const response = await api.post<
      ApiResponse<{ form: string; message?: string; data: { billno: string } }>
    >('/payment.php', reqBody)

    const { status, info } = response.data

    if (status === 1 || status === 2) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'begin_checkout',
          email,
          transaction_id: info.data.billno,
          value: +amount,
          currency: 'MXN',
          items: [
            {
              item_id: selectedPaymentChannel.id,
              item_name: selectedPaymentChannel.show_name,
              price: +amount,
              quantity: '1',
              currency: 'MXN',
            },
          ],
        },
      })

      // FB
      api.fbGraph({
        data: [
          {
            action_source: 'website',
            event_id: 12343,
            event_name: 'InitiateCheckout',
            event_time: dayjs().unix(),
            user_data: {
              em: [email ? sha256(email).toString() : null],
              ph: [phone ? sha256(phone).toString() : null],
              client_user_agent: navigator.userAgent,
              external_id: [userId ? sha256(userId).toString() : null],
              db: [birthday ? sha256(birthday).toString() : null],
              fn: [firstName ? sha256(firstName).toString() : null],
              ln: [lastName ? sha256(lastName).toString() : null],
            },
            custom_data: {
              transaction_id: info.data.billno,
              platform: selectedPaymentChannel.show_name,
              amount,
              currency: 'MXN',
            },
          },
        ],
      })
      api.fbGraph2({
        data: [
          {
            action_source: 'website',
            event_id: 12343,
            event_name: 'InitiateCheckout',
            event_time: dayjs().unix(),
            user_data: {
              em: [email ? sha256(email).toString() : null],
              ph: [phone ? sha256(phone).toString() : null],
              client_user_agent: navigator.userAgent,
              external_id: [userId ? sha256(userId).toString() : null],
              db: [birthday ? sha256(birthday).toString() : null],
              fn: [firstName ? sha256(firstName).toString() : null],
              ln: [lastName ? sha256(lastName).toString() : null],
            },
            custom_data: {
              transaction_id: info.data.billno,
              platform: selectedPaymentChannel.show_name,
              amount,
              currency: 'MXN',
            },
          },
        ],
      })
      // Twitter
      twq('event', 'tw-olsug-om2dy', {
        value: amount,
        currency: 'MXN',
        contents: [
          {
            transaction_id: info.data.billno,
            platform_id: selectedPaymentChannel.id,
            platform: selectedPaymentChannel.show_name,
            promotion: selectedPromo?.label ?? null,
            promotion_id: selectedPromo?.id ?? null,
          },
        ],
        email_address: email,
        phone_number: `+52${phone}`,
      })
      if (selectedPaymentChannel.need_bank_card === 1) {
        setUser({ pending_bind: 0, pending_amount: amount.toString() } as User)
      }

      if (selectedPaymentChannel.merchant === 'conekta' && tab) {
        tab.document.body.innerHTML = info.form
        const form = tab.document.getElementById('pay_form') as HTMLFormElement
        if (form) {
          form.submit()
        }
        setIsLoading(false)
      } else if (iframeRef.current) {
        iframeRef.current.classList.add('active')
        iframeRef.current.srcdoc = info.form
        const newForm = iframeRef.current.contentDocument?.getElementById(
          'pay_form',
        ) as HTMLFormElement
        newForm?.submit()
        setHasSubmitted(true)
      }
    } else {
      if (typeof info === 'string')
        messageModal.show({
          title: '',
          message: info,
        })
      setIsLoading(false)
    }
  }

  const showPromoTermsModal = () => {
    selectedPromo &&
      promotionTermsModal.show({
        htmlContent: selectedPromo.content,
        promoId: selectedPromo.id.toString(),
      })
  }

  const inputError = useMemo(() => {
    if (!selectedPaymentChannel) return ''
    if (selectedPaymentChannel.reduce_amount) {
      if (+amount > selectedPaymentChannel.reduce_amount && amount <= 99)
        return `${t('bank-will-charge')} $${
          selectedPaymentChannel.reduce_amount
        } ${t('commission-payment')}`
    }
    if (+amount === 0 || +amount < selectedPaymentChannel.pay_limit.minimum)
      return `*${t('minimum-deposit')} ${
        selectedPaymentChannel.pay_limit.minimum
      }.`
    if (+amount > selectedPaymentChannel.pay_limit.maximum)
      return `*${t('maximum-deposit')} ${
        selectedPaymentChannel.pay_limit.maximum
      }.`
    return ''
  }, [amount, selectedPaymentChannel])

  if (!selectedPaymentChannel) return null

  return (
    <>
      <Header>
        {(!isDesktop || (isDesktop && hasSubmitted && !isCrypto)) && (
          <button
            type="button"
            onClick={() => {
              if (hasSubmitted && iframeRef.current) {
                if (isCrypto) {
                  setSelectedPaymentChannel(null)
                } else {
                  iframeRef.current.classList.remove('active')
                  iframeRef.current.srcdoc = ''
                  setHasSubmitted(false)
                }
              } else {
                setSelectedPaymentChannel(null)
              }
            }}>
            <Icon id="back" width="25" height="25" fill="#ccc" />
          </button>
        )}
        <p>
          <img src={selectedPaymentChannel.img} alt="Platform Logo" />
          {selectedPaymentChannel.show_name}
        </p>
      </Header>
      <StyledIframe
        ref={iframeRef}
        onLoad={() => {
          setIsLoading(false)
        }}
        allow="clipboard-read self *; clipboard-write self *"
      />
      {((hasSubmitted && isLoading) || (isCrypto && isLoading)) && <Loading />}
      {!hasSubmitted && !isCrypto && (
        <>
          <div>
            <AmountSelector
              amount={amount}
              setAmount={setAmount}
              amounts={selectedPaymentChannel.fixed_amount}
            />
            <AmountInput>
              <label>
                {t('bonus')} $
                {selectedPromo
                  ? numberWithCommas(selectedPromo.bonus_amount)
                  : '0'}
              </label>
              <input
                inputMode="numeric"
                value={amount}
                onChange={e => {
                  if (/^\d*$/.test(e.target.value)) {
                    setAmount(Number(e.target.value))
                  }
                }}
                placeholder="Enter amount here"
                onFocus={e => {
                  setTimeout(() => {
                    e.target.selectionStart = e.target.selectionEnd = 10000
                  }, 0)
                }}
              />
            </AmountInput>
            <div className="flx-btw-ctr">
              {selectedPromo?.multiple && (
                <div>
                  <Total>{t('required-bet')}</Total>
                  <Total>
                    $
                    {numberWithCommas(
                      (Number(selectedPromo.bonus_amount) + Number(amount)) *
                        Number(selectedPromo.multiple),
                    )}
                  </Total>
                </div>
              )}
              <div
                style={{
                  width: !selectedPromo?.multiple ? '100%' : undefined,
                }}>
                <Total>{t('total-credit')}</Total>
                <Total>
                  $
                  {selectedPromo
                    ? numberWithCommas(
                        Number(selectedPromo.bonus_amount) + Number(amount),
                      )
                    : numberWithCommas(Number(amount))}
                </Total>
              </div>
            </div>
            {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
            <PromoList>
              {promoList.map(promo => (
                <PromoItem
                  className={selectedPromo?.id === promo.id ? 'active' : ''}
                  key={promo.id}
                  onClick={() => {
                    if (promo.disabled && promo.disabled_reason) {
                      return toastMessage.show({
                        text: promo.disabled_reason,
                        isValid: false,
                      })
                    }
                    setSelectedPromo(
                      selectedPromo?.id === promo.id ? null : promo,
                    )
                  }}>
                  <p className="name">{promo.label}</p>
                  {promo.text && <p className="text">{promo.text}</p>}
                  <img className="icon" src={gift} alt="gift" />
                </PromoItem>
              ))}
            </PromoList>
            {selectedPromo && (
              <TermsCheckbox
                isChecked={isReadTerms}
                setIsChecked={setIsReadTerms}
                onClick={showPromoTermsModal}
              />
            )}
            <SubmitContainer>
              {isLoading ? (
                <Spinner />
              ) : (
                <DefaultButton
                  variant="blue"
                  width={'100%'}
                  disabled={
                    !Number(amount) ||
                    Number(amount) <
                      Number(selectedPaymentChannel.pay_limit.minimum) ||
                    Number(amount) >
                      Number(selectedPaymentChannel.pay_limit.maximum)
                  }
                  onClick={() => {
                    let tab = null
                    if (selectedPaymentChannel.merchant === 'conekta') {
                      tab = window.open()
                      if (tab) {
                        tab.document.body.innerHTML = LOADING_HTML
                      }
                    }
                    onSubmit(tab)
                  }}>
                  {t('deposit')}
                </DefaultButton>
              )}
            </SubmitContainer>
          </div>
        </>
      )}
    </>
  )
}

export default PaymentForm
